<template>
  <div class="retro-data">
    <div class="retro-data__header">
      <div class="retro-data__header__text">
        {{ info.title }}
      </div>
      <div class="retro-data__header__icon">
        <img v-if="info.retro_template_id === 1" src="/icons/icn-crown.png" alt="type" />
      </div>
    </div>
    <div class="retro-data__body">
      <div class="retro-data__body__img">
        <img :src="`https://az755631.vo.msecnd.net/teams-provisional-retro/${info.team_id}.png`" alt="team" />
      </div>
      <div class="retro-data__body__info">
        <div v-html="infoDescription" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RetroData',
  props: {
    /**
     * example data
     * description: "*Miguel Ponce Briseño* Se convierte en el máximo goleador del torneo con 25 goles anotados."
     * jersey: 16
     * player_id: 5
     * provisional_logo: "https://az755631.vo.msecnd.net/teams-provisional/1.png"
     * retro_template_id: 2
     * season_id: 1401
     * team_id: 1
     * title: "Campeón de Goleo"
     * type: 1 - Type 0 is for 'Campeon'
     */
    info: {
      type: Object,
      default: () => {
        return {
          description: '',
          jersey: 0,
          player_id: 0,
          provisional_logo: '',
          retro_template_id: 0,
          season_id: 0,
          team_id: 0,
          title: '',
          type: null,
        };
      },
    },
  },
  computed: {
    infoDescription() {
      let infoDescription = this.info.description;
      if (infoDescription) {
        const matches = this.info.description.match(/\*/g);
        if (matches && matches.length) {
          if (matches.length % 2 === 0) {
            for (let i = 1; i <= matches.length; i++) {
              if (i % 2 === 0) {
                infoDescription = infoDescription.replace('*', '</span>');
              } else {
                infoDescription = infoDescription.replace(
                  '*',
                  '<span style="font-family: Avenir-Pro-Bold, sans-serif;" >',
                );
              }
            }
          } else {
            infoDescription = infoDescription.replaceAll('*', '');
          }
        }
      }
      return infoDescription;
    },
  },
};
</script>

<style scoped lang="scss">
.retro-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 1px 0 0 #d9d9d9, inset 0 -1px 0 0 #d9d9d9;
  padding: 0.5rem;

  &__header {
    display: flex;
    width: 100%;
    justify-content: center;

    &__text {
      font-family: Avenir-Pro-Bold, sans-serif;
      font-size: 1.1rem;
    }

    &__icon {
      margin-left: 0.4rem;
    }

    @media screen and (max-width: 600px) {
      justify-content: left;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 40px 1fr;
    column-gap: 1rem;

    &__info {
      align-self: center;
      font-family: Avenir-Medium, sans-serif;
      color: #4e4f4f;
    }

    &__img {
      align-self: center;

      & > img {
        height: 40px;
        width: 40px;
      }
    }

    @media screen and (max-width: 600px) {
      &__info {
        align-self: center;
        justify-self: left;
        text-align: left;
      }

      &__img {
        align-self: center;

        & > img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}
</style>
